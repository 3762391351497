.shadow{
    box-shadow: none !important;
    top: 79px !important;
    z-index: 1000 !important;
    font-weight: 400;
    font-stretch: normal;
    direction: ltr;
    box-sizing: border-box;
    text-align: start;
    -webkit-font-smoothing: antialiased;
    z-index: 1000;
    overflow-anchor: none;
}

.nav_list{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    transition: margin .2s ease-out,opacity .15s linear .2s;
    opacity: 1;
}

@media (max-width: 768px) and (min-width: 600px) {
    .shadow {
        top: 88px !important;
    }
  }