.search {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .searchTerm {
    width: 50%;
    border: 1px solid #1976d2;
    border-right: none;
    padding: 5px;
    height: 44px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #9dbfaf;
  }
  .searchTerm::-webkit-input-placeholder {
    /* Edge */
    padding-left: 10px;
    font-weight: 600;
  }
  
  .searchTerm:focus {
    color: black;
  }
  
  .searchButton {
    width: 70px;
    height: 44px;
    border: 1px solid #0f69ff;
    background: #0f69ff;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
  }

  .signIn:hover{
    color: black;
    cursor: pointer;
  }

  .signUp:hover{
    color: black;
    cursor: pointer;
  }
  

  
@media (max-width: 768px) and (min-width: 280px) {
  .search {
    display: none;
  }
}

@media (max-width: 900px) and (min-width: 769px) {
 .search {
    width: 55%;
    display: flex;
    justify-content: center;
  }
}