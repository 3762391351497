/* Spinners */
.loader_spinner {
    position: fixed;
    height: 100%;
    width: 60px;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
    z-index:101; 
    opacity:1.0;
}

.loader_spinner div {
  animation: loader_spinner 1.2s linear infinite;
  transform-origin: 30px 30px;
  
}

.loader_spinner div:after {
  position: absolute;
  top: 6px;
  left: 27px;
  border-radius: 30%;
  content: " ";
  height: 10px;
  width: 4px;
}

.loader_spinner div:nth-child(1) {
  animation-delay: -1.1s;
  transform: rotate(0deg);
}

.loader_spinner div:nth-child(2) {
  animation-delay: -1s;
  transform: rotate(30deg);
}

.loader_spinner div:nth-child(3) {
  animation-delay: -0.9s;
  transform: rotate(60deg);
}

.loader_spinner div:nth-child(4) {
  animation-delay: -0.8s;
  transform: rotate(90deg);
}

.loader_spinner div:nth-child(5) {
  animation-delay: -0.7s;
  transform: rotate(120deg);
}

.loader_spinner div:nth-child(6) {
  animation-delay: -0.6s;
  transform: rotate(150deg);
}

.loader_spinner div:nth-child(7) {
  animation-delay: -0.5s;
  transform: rotate(180deg);
}

.loader_spinner div:nth-child(8) {
  animation-delay: -0.4s;
  transform: rotate(210deg);
}

.loader_spinner div:nth-child(9) {
  animation-delay: -0.3s;
  transform: rotate(240deg);
}

.loader_spinner div:nth-child(10) {
  animation-delay: -0.2s;
  transform: rotate(270deg);
}

.loader_spinner div:nth-child(11) {
  animation-delay: -0.1s;
  transform: rotate(300deg);
}

.loader_spinner div:nth-child(12) {
  animation-delay: 0s;
  transform: rotate(330deg);
}

@keyframes loader_spinner {
  0% {
      opacity: 1;
  }

  100% {
      opacity: 0;
  }
}


.loader_spinner div:after {
  background: #00539f;
}

/* .loader_spinner div:after {
  background: #9db6db;
} */
