.main_container {
    padding: 20px 10px;

    z-index: 4000;
    margin-top: 20px;
    /* position: inherit; */
    /* bottom: 0; */
  }
  
  .footer_body {
    font-size: 14px;
    color: grey;
  }
  
  .bar {
    border: 1px solid rgba(128, 128, 128, 0.292);
  }
  
  @media (max-width: 425px) {
    .main_container {
      padding-top: 50px;
    }
  }
  @media (max-width: 320px) {
    .main_container {
      padding-top: 150px;
    }
  }
  @media (max-width: 375px) {
    .main_container {
      padding-top: 0px;
    }
  }