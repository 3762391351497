

.close svg{
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
}


@media (min-width: 720px) and (max-width: 2560px) {
    .element{
        position: fixed;
        z-index: 1100;
        width: 300px;
        height: 48% !important;
        right: 0px;
        top: 50px;
        transform: translateX(0px) translateZ(0px);
       
    }
  }


@media (min-width: 280px) and (max-width: 719px) {
    .element{
        position: fixed;
        z-index: 1100;
        width: 300px;
        height: 50% !important;
        right: 0px;
        top: 50px;
        transform: translateX(0px) translateZ(0px);
    }
  }