html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
 
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: gray;
  }
  body {
    color: white;
    background: black;
    background-color: #f0f2f5 !important;
  }
}

body::-webkit-scrollbar {
  display: none;
  overflow: hidden;
  scroll-behavior: smooth;
}


.react-read-more-read-less,
.react-read-more-read-less-more {
  color: #4fabc9;
}

/*-----------------------------------
-------------------------------------    POST GLOBAL STYLE  -------------------------------------- 
                               --------------------------------------*/

.summary_text {
  font-weight: 600;
}

@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  list-style: none;
  text-decoration: none;
  border: none;
}

.post {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.post-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.post-user-info {
  display: flex;
  align-items: center;
}


.profile {
  font-size: 15px;
  color: black;
  font-weight: 600;
}

.profile span {
  display: flex;
  color: black;
  font-size: 15px;
  font-weight: 600;
}

.profile-name:hover{
  color: #0d6efd;
  cursor: pointer;
}

.profile h3 a:hover,
.post-time a:hover,
.comment a:hover,
.like a:hover,
.all-com a:hover,
.user-name:hover {
  text-decoration: none !important;

}
.post-time a {
  color: rgb(176, 179, 184);
  font-size: 13px;
  font-weight: 600;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}
.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.post-settings {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.post-settings:hover {
  background-color: rgb(159, 207, 255);
}
.content {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}
.inte {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.comment a {
  color: rgb(176, 179, 184);
  font-size: 15px;
  font-weight: 400;
}

.comment span{
  color: rgb(176, 179, 184);
  font-size: 15px;
  font-weight: 400;
}



.like_color {
  color: red;
}
.like a {
  font-size: 15px;
  color: rgb(176, 179, 184);
}
.like:hover {
  margin-right: 6px;
  color: rgb(19, 159, 249);
}

.all-com {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 9px 0 5px 0;
}
.all-com a {
  color: rgb(176, 179, 184);
  font-size: 15px;
  font-weight: 600;
}
.users-comments {
  display: flex;
  flex-direction: column;
}
.users-comments li {
  display: flex;
 
}
.users-comments-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}
.users-comments-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.users-com {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 8px;
}
.user-name {
  font-size: 13px;
  font-weight: 600;
 
}
.user-com {
  font-size: 13px;
  font-weight: 400;
}
.my-com {
  display: flex;
}
.inp-com {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.inp-com textarea {
  width: 100%;
  height: 36px;
  border-radius: 18px;
  padding: 5px 55px 5px 12px;
  background-color: rgb(233, 227, 227);
  cursor: pointer;
}
.inp-buttons {
  display: flex;
  height: 34px;
  width: 75px;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}
.inp-buttons li  {
  margin-right: 5px;
  padding: 4px 6px;
 
}
.inp-buttons :hover {
  background-color:  rgb(159, 207, 255);
  color: green;
  border-radius: 50%;
 
 
}
.inp-buttons li:last-child  {
  margin-right: 0;
}

a:link {
  text-decoration: none !important;
}


.swal2-container {
  z-index: 20000;
}

.swal2-container {
  z-index: 20000 !important;
}

.cursor{
  cursor: pointer;
}
