.element{
  position: fixed;
  z-index: 1100;
  width: 300px;
  height: 50% !important;
  right: 0px;
  top: 50px;
  transform: translateX(0px) translateZ(0px);
}


.navbar_main_container {
  height: 52px;
  padding: 10px;
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 1000;
  /* background-color: #ffff; */
}


.nav__search {
  display: flex;
  align-items: center;
  background-color: #f1f2f6;
  padding: 20px;
  border-radius: 50px;
  height: 40px;
  color: #1876f2;

}

.nav__search input {
  outline: none;
  border: none;
  background: transparent;
  padding: 0 10px;
}

.nav__search input::placeholder {
  font-size: 14px;
}

/*------- Icon active / visited---------- */

.mid_section a:visited,
.mid_section a:focus,
.mid_section a:active {
  border-bottom: 1px solid #1876f2;
  transform: scale(1.08);
}

@media (max-width: 2560px) and (min-width: 768px) {
  .nav__search {
    display: flex;
    align-items: center;
    background-color: #f1f2f6;
    padding: 20px;
    border-radius: 50px;
    height: 40px;
    color: #1876f2;
    width: 30%;
  }
}



@media (max-width: 767px) {
  .mid_section {
    display: none !important;
  }
}

@media (max-width: 500px) {
  .nav__search {
    display: none;
  }
}

.nav_hamber{
  color: #0572E6;
  transition: 0.3s ease;

}